import React from 'react';
import './style.scss';

function DiscussOnReddit({ post }) {
    return (
      <div>
        <div className='cntr'>
            <a className="my-button" href={post.redditUrl} target="_blank"><span></span>Join Reddit Discussion</a>
        </div>
        <p>&nbsp;</p>
      </div>
    );
  }
  export default DiscussOnReddit;